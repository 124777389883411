<template>
  <el-row>
    <el-col :span="24">
      <el-card class="box-card">
        <div slot="header" class="clearfix">
          <span>账目明细</span>
          <el-button
            style="float: right; padding: 3px 0"
            type="text"
            @click="down"
            >导出明细</el-button
          >
          <a
            id="dl"
            href="未交明细.xls"
            download="未交明细.xls"
            style="display: none"
            >Download</a
          >
        </div>
        <div class="text item">
          总金额：<el-tag type="danger">{{ total }}</el-tag>
        </div>
        <div class="text item">
          已交金额：<el-tag type="success">{{ have }}</el-tag>
        </div>
        <div class="text item">
          总条数：<el-tag type="danger">{{ totalCount }}</el-tag>
        </div>
        <div class="text item">
          已交条数：<el-tag type="success">{{ haveCount }}</el-tag>
        </div>
        <div class="text item">
          未交条数：<el-tag type="warning">{{ totalCount - haveCount }}</el-tag>
        </div>
      </el-card></el-col
    ></el-row
  >
</template>
<script>
import fileDownload from "js-file-download";
import FileSaver from "file-saver";

export default {
  created() {
    let self = this;
    this.rq({ url: "Settle", method: "post" })
      .then((res) => {
        //debugger;
        self.total = res.data.total;
        self.have = res.data.have;
        self.totalCount = res.data.totalCount;
        self.haveCount = res.data.haveCount;
      })
      .catch((err) => {});
  },
  data() {
    return {
      total: 0,
      have: 0,
      totalCount: 0,
      haveCount: 0,
    };
  },
  methods: {
    down() {
      this.rq({ url: "Settle", method: "put", responseType: "blob" })
        .then((res) => {
          let blob = new Blob([res.data], { type: "application/vnd.ms-excel" });
          //FileSaver.saveAs(blob, "交费明细.xls");

          //const url = window.URL.createObjectURL(
          //  new Blob([res.data], { type: "application/vnd.ms-excel" })
          //);
          //saveAs(url, "交费明细.xls");
          //fileDownload(res.data, "交费明细.xls");
          //debugger;
          const link = document.createElement("a");
          link.href =
            "https://pay.1000cb.com/%E4%BA%A4%E8%B4%B9%E6%98%8E%E7%BB%86.xls";
          //link.href = "../../未交明细.xls";
          link.setAttribute("download", "交费明细.xls");
          //debugger;
          //http://localhost:8080/%E6%9C%AA%E4%BA%A4%E6%98%8E%E7%BB%86.xls
          //var dl = document.getElementById("dl");
          //dl.click();
          //document.body.appendChild(link);
          //debugger;
          debugger;
          link.click();
        })
        .catch((err) => {});
    },
  },
};
</script>